

<template>

  <header class="header">
    <div class="top-container">
      <div class="navbar-primary affixnav" data-spy="affix" data-offset-top="100">
        <div class="container">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <div class="logo-image">
              <a href="#" class="brand js-target-scroll">
                <img src="@/assets/images/logo-light-blue.png" alt="logo-image" class="logo-normal" style="width: 135px" />
                <img src="@/assets/images/logo-blue.png" alt="logo-image" class="logo-hover" style="width: 135px" />
              </a>
            </div>
          </div>
          <div class="collapse navbar-collapse" id="navbar-collapse">
            <ul class="nav navbar-nav navbar-right underline">
              <li :class="{ 'active': isHomeActive }">
                <router-link to="/" class="js-target-scroll">HOME</router-link>
              </li>
              <li :class="{ 'active': isAboutActive }">
                <router-link to="/conoceme" class="js-target-scroll">SOBRE MI</router-link>
              </li>
              <li :class="{ 'active': isVoiceoverActive }">
                <router-link to="/voiceover" class="js-target-scroll">VOICE OVER</router-link>
              </li>
              <li :class="{ 'active': isMentoringActive }">
                <router-link to="/mentorias" class="js-target-scroll">MENTOR&Iacute;AS</router-link>
              </li>
              <li :class="{ 'active': isPodcastActive }">
                <router-link to="/podcast" class="js-target-scroll">PODCAST</router-link>
              </li>
              <li :class="{ 'active': isContactActive }">
                <router-link to="/contacto" class="js-target-scroll">CONT&Aacute;CTAME</router-link>
              </li>

            </ul>

          </div>

        </div>
      </div>
    </div>
  </header>

</template>

<script>

export default {
  name: "Header",
  computed: {
    isHomeActive() {
      return this.$route.path === '/'
    },
    isAboutActive() {
      return this.$route.path === '/about'
    },

    isVoiceoverActive() {
      return this.$route.path === '/voiceover'
    },
    isMentoringActive() {
      return this.$route.path === '/mentoring'
    },
    isPodcastActive() {
      return this.$route.path === '/podcast'
    },
    isContactActive() {
      return this.$route.path === '/contact'
    },
  },

}
</script>

<style scoped>

</style>
