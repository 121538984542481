export default {
    mounted() {
        if (window.jQuery && $.fn.stellar) {
            $(window).stellar();
        }
    },
    watch: {
        '$route'() {
            this.$nextTick(() => {
                if (window.jQuery && $.fn.stellar) {
                    $(window).stellar('refresh');
                }

            });
        }
    }
}
