import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'

import stellarMixin from '@/modules/share/mixins/stellarMixin'

const app = createApp(App)

app.mixin(stellarMixin)

app.config.globalProperties.$publicPath = process.env.BASE_URL || 'https://majoestevez.com/'

app.use(router).mount('#app')

// createApp(App)
//     .use(router)
//     .mount('#app')
