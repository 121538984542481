<script>

const acastChapters = [
  {
    chapter: 'TRAILER',
    title: 'SINOPSIS',
    description: 'En la ficción, villanos y héroes nos enseñaron que sólo hay historia con esas dos fuerzas en pugna y ¿qué pasa en nuestra mente? ¿cuál es el cuento que nos contamos? ¿ Tú también escuchas esas 2 voces dentro de tí?. Como locutora, por muchos años le he dado voz a personajes, marcas comerciales y jingles. Esta vez quiero que exploremos juntos las voces que escuchamos en la mente y cómo ponerlas a nuestro favor. No me considero experta ni pretendo ninguna etiqueta, soy Majo Estevez con “Ser tu Voz” un Podcast para escucharte.',
    coverImage: require('@/assets/images/podcast/chapter_00.png'),
    iframeAudio: 'https://embed.acast.com/63e70156fe8ea4001014431a/63e703f55c30a700102de7ae?theme=light&cover=true'
  },
  {
    chapter: 'EPISODIO 01',
    title: 'RECONOCE LAS VOCES EN TU MENTE',
    description: 'Hay dos fuerzas opuestas pero que se complementan y combinan en una sola unidad. Una no puede existir sin la otra. Es enriquecedor aprender a escucharlas y entender que ninguna es buena ni es mala, simplemente coexisten. Conoce un poco de mi historia, de por qué hago este podcast y cómo pienso ayudarte en el camino de Ser tu Voz.',
    coverImage: require('@/assets/images/podcast/chapter_01.png'),
    iframeAudio: 'https://embed.acast.com/63e70156fe8ea4001014431a/63ff8e921cae730011fdaafa?theme=light&cover=true'
  },
  {
    chapter: 'EPISODIO 02',
    title: 'LA HISTORIA DE LOS ZAPATOS ROJOS',
    description: 'La mente nos distrae en asuntos que al fin y al cabo no importan tanto como creemos.',
    coverImage: require('@/assets/images/podcast/chapter_02.png'),
    iframeAudio: 'https://embed.acast.com/63e70156fe8ea4001014431a/6410b7147088820011ddca7e?theme=dark&cover=true'
  },
  {
    chapter: 'EPISODIO 03',
    title: 'LA VOZ DEL MIEDO',
    description: 'Le di permiso al miedo de encontrarse conmigo y así poder entenderlo en varios niveles de profundidad. Esta ha sido la voz que me hizo frenar durante mucho tiempo, pero también la que más me ha impulsado a accionar. Si escuchas quizás te ayude a ti, si es que lo estás enfrentando también.',
    coverImage: require('@/assets/images/podcast/chapter_03.png'),
    iframeAudio: 'https://embed.acast.com/63e70156fe8ea4001014431a/6422f9ab304760001166a3a6?theme=light&cover=true'
  },
  {
    chapter: 'EPISODIO 04',
    title: 'EL ENEMIGO ESCONDIDO',
    description: 'La voz que más hace daño, no es la que hace más ruido, es la que te engancha a tus egos y deja en silencio tus virtudes. Te controla sin que te des cuenta. Quizás te sea incómodo de escuchar, esa es la idea. Yo descubrí que mi peor enemigo, no es el miedo. Escucha y dime si sientes lo mismo. NOTA: Dedico este episodio a mi querido Elnor, quien partió de este plano justo cuando terminaba de grabarlo. Como no creo en las casualidades lo comento, porque además Elnor fue una de las personas que encendió la chispa que me hizo tomar la decisión de emprender este camino. Por ello y muchas cosas más le estaré eternamente agradecida. Gracias amigo!',
    coverImage: require('@/assets/images/podcast/chapter_04.png'),
    iframeAudio: 'https://embed.acast.com/63e70156fe8ea4001014431a/6436ce7ff8339200119f683a?theme=dark&cover=true'
  },
  {
    chapter: 'EPISODIO 05',
    title: 'LAS VOCES DE LA AUTOEXIGENCIA',
    description: 'Cómo aprender a controlar las voces que te presionan a niveles no sanos de autoexigencia. Quédate hasta el final que te tengo un regalo.',
    coverImage: require('@/assets/images/podcast/chapter_05.png'),
    iframeAudio: 'https://embed.acast.com/63e70156fe8ea4001014431a/644839ead11ac0001012a87e?theme=light&cover=true'
  }
]

export default {
  name: 'AboutView',
  data() {
    return {
      acastChapters
    }
  },
  computed: {
    backgroundImage() {
      return `background-image: url(${require('@/assets/images/1920x480.png')})`;
    }
  }
}
</script>

<template>

  <!-- banner starts -->
  <section  id="banner-podcast" class="banner-podcast" data-stellar-background-ratio=".2">
    <video class="background-video" autoplay loop muted src="https://d15gxqlqiq7uiv.cloudfront.net/websites/majoestevez.com/media/videos/video_header.mp4"></video>
    <div class="layer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  text-center">
            <div class="blog-text">
              <h1 class="banner_heading">SER TU VOZ</h1>
              <h6 class="slider-sub-heading gettoknow">UN PODCAST PARA ESCUCHARTE</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- banner ends -->

  <main class="main home-v4">

    <section id="how-it-works" class="how-it-works">
      <div class="container">



<!--        <iframe :src="audioChapter00iFrame"></iframe>-->

<!--        <div class="row">-->
<!--          <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6 text-center">-->
<!--            <div class="how-it-works-text fadeInDown wow" data-wow-duration="1.5s">-->
<!--              <h6>HOW IT WORKS</h6>-->
<!--              <h1>HOW IT WORKS</h1>-->
<!--              <hr class="home-v4-hr">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->



        <!-- Chapter 00 -->
        <div class="row v2-services">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 responsive-col1-change">
            <div class="v2-services-text text-right">
              <h6>TRAILER</h6>
              <h1 class="services-heading">SINOPSIS</h1>
              <p>En la ficción, villanos y héroes nos enseñaron que sólo hay historia con esas dos fuerzas en pugna y ¿qué pasa en nuestra mente? ¿cuál es el cuento que nos contamos? ¿ Tú también escuchas esas 2 voces dentro de tí?. Como locutora, por muchos años le he dado voz a personajes, marcas comerciales y jingles. Esta vez quiero que exploremos juntos las voces que escuchamos en la mente y cómo ponerlas a nuestro favor. No me considero experta ni pretendo ninguna etiqueta, soy Majo Estevez con “Ser tu Voz” un Podcast para escucharte.</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right responsive-col3-change fadeInRight wow" data-wow-duration="1.5s">
            <div class="image-number hidden-xs hidden-sm">
<!--              <hr class="number-top-hr">-->
<!--              <span>00</span>-->
<!--              <hr>-->
            </div>
            <div class="image shadow-image-right image-container-right" >
              <img class="img-responsive " :src="acastChapters[0].coverImage" alt="planning" />
              <div class="iframe-wrapper">
                <iframe :src="acastChapters[0].iframeAudio" frameBorder="0" width="100%" height="80px" class="custom-iframe"></iframe>
              </div>
            </div>
          </div>
        </div>

        <!-- Chapter 01 -->
        <div class="row v2-services">
          <div class="col-xs-12 col-sm-6 col-md-6  col-lg-6 text-left responsive-col3-change fadeInLeft wow" data-wow-duration="1.5s">
            <div class="image image-container" >
              <img class="img-responsive " :src="acastChapters[1].coverImage" alt="planning" />
              <div class="iframe-wrapper">
                <iframe :src="acastChapters[1].iframeAudio" frameBorder="0" width="100%" height="80px" class="custom-iframe"></iframe>
              </div>
            </div>
            <div class="image-number-right-align hidden-xs hidden-sm">
<!--              <hr class="number-top-hr">-->
<!--              <span>01</span>-->
<!--              <hr>-->
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 responsive-col1-change">
            <div class="v2-services-text text-left">
              <h6>EPISODIO 01</h6>
              <h1 class="services-heading">RECONOCE LAS VOCES EN TU MENTE</h1>
              <p>Hay dos fuerzas opuestas pero que se complementan y combinan en una sola unidad. Una no puede existir sin la otra. Es enriquecedor aprender a escucharlas y entender que ninguna es buena ni es mala, simplemente coexisten.
              <br>
                Conoce un poco de mi historia, de por qué hago este podcast y cómo pienso ayudarte en el camino de Ser tu Voz.</p>
            </div>
          </div>
        </div>

        <!-- Chapter 02 -->
        <div class="row v2-services">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 responsive-col1-change">
            <div class="v2-services-text text-right">
              <h6>EPISODIO 02</h6>
              <h1 class="services-heading">LA HISTORIA DE LOS ZAPATOS ROJOS</h1>
              <p>La mente nos distrae en asuntos que al fin y al cabo no importan tanto como creemos.</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right responsive-col3-change fadeInRight wow" data-wow-duration="1.5s">
            <div class="image-number hidden-xs hidden-sm">
<!--              <hr class="number-top-hr">-->
<!--              <span>02</span>-->
<!--              <hr>-->
            </div>

            <div class="image shadow-image-right image-container-right" >
              <img class="img-responsive " :src="acastChapters[2].coverImage" alt="planning" />
              <div class="iframe-wrapper">
                <iframe :src="acastChapters[2].iframeAudio" frameBorder="0" width="100%" height="80px" class="custom-iframe"></iframe>
              </div>
            </div>
          </div>
        </div>

        <!-- Chapter 03 -->
        <div class="row v2-services">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-left responsive-col3-change fadeInLeft wow" data-wow-duration="1.5s">
            <div class="image image-container" >
              <img class="img-responsive " :src="acastChapters[3].coverImage" alt="planning" />
              <div class="iframe-wrapper">
                <iframe :src="acastChapters[3].iframeAudio" frameBorder="0" width="100%" height="80px" class="custom-iframe"></iframe>
              </div>
            </div>
            <div class="image-number-right-align hidden-xs hidden-sm">
<!--              <hr class="number-top-hr">-->
<!--              <span>03</span>-->
<!--              <hr>-->
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 responsive-col1-change">
            <div class="v2-services-text text-left">
              <h6>EPISODIO 03</h6>
              <h1 class="services-heading">LA VOZ DEL MIEDO</h1>
              <p>Le di permiso al miedo de encontrarse conmigo y así poder entenderlo en varios niveles de profundidad. Esta ha sido la voz que me hizo frenar durante mucho tiempo, pero también la que más me ha impulsado a accionar.<br>
                Si escuchas quizás te ayude a ti, si es que lo estás enfrentando también.</p>
            </div>
          </div>
        </div>

        <!-- Chapter 04 -->
        <div class="row v2-services">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 responsive-col1-change">
            <div class="v2-services-text text-right">
              <h6>EPISODIO 04</h6>
              <h1 class="services-heading">EL ENEMIGO ESCONDIDO</h1>
              <p>La voz que más hace daño, no es la que hace más ruido, es la que te engancha a tus egos y deja en silencio tus virtudes. Te controla sin que te des cuenta.
                <br>
                Quizás te sea incómodo de escuchar, esa es la idea. Yo descubrí que mi peor enemigo, no es el miedo. Escucha y dime si sientes lo mismo.
                <br>
                NOTA: Dedico este episodio a mi querido Elnor, quien partió de este plano justo cuando terminaba de grabarlo. Como no creo en las casualidades lo comento, porque además Elnor fue una de las personas que encendió la chispa que me hizo tomar la decisión de emprender este camino. Por ello y muchas cosas más le estaré eternamente agradecida. Gracias amigo!
              </p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right responsive-col3-change fadeInRight wow" data-wow-duration="1.5s">
            <div class="image-number hidden-xs hidden-sm">
<!--              <hr class="number-top-hr">-->
<!--              <span>04</span>-->
<!--              <hr>-->
            </div>

            <div class="image shadow-image-right image-container-right" >
              <img class="img-responsive " :src="acastChapters[4].coverImage" alt="planning" />
              <div class="iframe-wrapper">
                <iframe :src="acastChapters[4].iframeAudio" frameBorder="0" width="100%" height="80px" class="custom-iframe"></iframe>
              </div>
            </div>
          </div>
        </div>

        <!-- Chapter 05 -->
        <div class="row v2-services">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-left responsive-col3-change fadeInLeft wow" data-wow-duration="1.5s">
            <div class="image image-container" >
              <img class="img-responsive " :src="acastChapters[5].coverImage" alt="planning" />
              <div class="iframe-wrapper">
                <iframe :src="acastChapters[5].iframeAudio" frameBorder="0" width="100%" height="80px" class="custom-iframe"></iframe>
              </div>
            </div>
            <div class="image-number-right-align hidden-xs hidden-sm">
<!--              <hr class="number-top-hr">-->
<!--              <span>05</span>-->
<!--              <hr>-->
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 responsive-col1-change">
            <div class="v2-services-text text-left">
              <h6>EPISODIO 05</h6>
              <h1 class="services-heading">LAS VOCES DE LA AUTOEXIGENCIA</h1>
              <p>Cómo aprender a controlar las voces que te presionan a niveles no sanos de autoexigencia. Quédate hasta el final que te tengo un regalo.</p>
            </div>
          </div>
        </div>

      </div>
    </section>


  </main>

</template>

<style scoped>
/* Clase para el div contenedor de la imagen */
.image-container {
  position: relative;
}

.image-container-right {
  position: relative;
  display: inline-block; /* Hace que el contenedor se ajuste al tamaño de la imagen */
  text-align: right; /* Alinea la imagen y el iframe a la derecha */
}


/* Clase para el div que envuelve el iframe */
.iframe-wrapper {
  position: absolute;
  bottom: 0;
  width: 500px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Clase para el iframe */
.custom-iframe {
  width: 100%;
  height: 80px;
  border: 0; /* Para remover el borde del iframe */
  background: transparent;
}

.banner-podcast {
  position: relative;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

</style>
