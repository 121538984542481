<template>
  <div class="home-v5">
    <!--         banner starts-->
    <section :style="backgroundImage" class="home-page-v2-banner" data-stellar-background-ratio=".2">
      <div class="image-caption-1 ">
        <div class="container banner-caption" style="padding-top: 61px;">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <h6 class="slider-sub-heading fadeInUp wow" data-wow-duration="1s">ES TIEMPO DE</h6>
              <h1 class="slider-heading fadeInRight wow" data-wow-duration="1s">SER TU VOZ</h1>
              <hr>
              <p class="boss-slider-info fadeInDown wow" data-wow-duration="1s">
                La honestidad, la coherencia, la seguridad SUENAN EN LA VOZ. Si quieres diferenciarte entre todo el
                bullicio digital, quieres dejar huella e inspirar a otros, debes comenzar contigo.
                <br><br>
                TRANFORMA TU COMUNICACIÓN, DE ADENTRO HACIA AFUERA!
              </p>
              <router-link to="/mentoring" class="btn btn-primary">Quiero ser mi voz!</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <main class="main">

    <!-- clients starts -->
    <section id="clients" class="clients">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="">
              <carousel :autoplay="2000" :items-to-show="4.5" :wrap-around="true">
                <slide v-for="slide in slides" :key="slide.id">
                  <div class="item">
                    <img class="img-responsive" :src="slide.image" :alt="slide.title">
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- clients ends -->


    <!-- our-history starts -->
    <section id="our-history startchange" class="our-history">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="history-image">
              <img class="img-responsive" src="@/assets/images/history.png" alt="history-image"/>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="fadeInRight wow" data-wow-duration="1s">
              <h6>Soy</h6>
              <h1>Majo Estévez</h1>
              <hr class="hr-bg">
              <p><span>
                                    Quiero que la inspiración suene y se sienta en las voces de cada persona, en una versión más auténtica y repotenciada de su yo esencial, para así comunicar de forma clara, segura y potente las ideas brillantes que merecen ser escuchadas!
                                </span></p>
              <p><span>
                                    Soy VoiceOver, Mentora de Voz y Consultora de Psicomarketing y Comunicación estratégica. Mis servicios están destinados a profesionales que necesitan crecer en el mundo digital.<br>
Aplico herramientas nunca utilizadas en el trabajo vocal y en el marketing para crear una conexión más auténtica, impactante e influyente con la audiencia en el mercado actual, partiendo del trabajo integral del Ser
                                </span></p>
              <router-link to="/about" class="btn btn-primary">Conóceme más</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- our-history ends -->

    <!-- cta starts -->
    <section id="cta" class="cta">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9">
            <div class="buynow-text">
              <h1 class="slider-heading buynow-head fadeInLeft wow" data-wow-duration="1s">
                ¡Tu propia voz es el megáfono  de tu marca personal!
              </h1>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 text-right">
            <div class="buynow-button">
              <router-link to="/mentoring" class="btn btn-default">Despierta tu voz</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- cta ends -->

    <!-- testimonials starts -->
    <section :style="backgroundTestimonials" class="testimonials" data-stellar-background-ratio=".2">

      <!--      <section  id="testimonials" class="testimonials">-->
      <div class="layer">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-offset-1 col-lg-10">
              <carousel :autoplay="5000" :wrap-around="true">
                <slide v-for="testimonial in testimonials" :key="testimonial.id" id="testimonial-slider">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="pic">
                        <!--                      <img class="" src="@/assets/images/80x80.png" alt="">-->
                      </div>
                      <span class="title">{{ testimonial.name }}</span>
                      <span class="post">{{ testimonial.title }}</span>
                    </div>
                    <p class="description">
                      {{ testimonial.description }}
                    </p>
                  </div>
                </slide>
              </carousel>


              <div id="testimonial-slider" class="owl-carousel">
                <div class="testimonial">
                  <div class="testimonial-content">
                    <div class="pic">
                      <!--                      <img class="" src="@/assets/images/80x80.png" alt="">-->
                    </div>
                    <span class="title">Alberto Chumaceiro</span>
                    <span class="post">Director General y cofundador de Fénix Media</span>
                  </div>
                  <p class="description">
                    " Trabajar con Majo ha sido un verdadero privilegio para Fénix Media y para mí a título personal.
                    Nuestra colaboración ha abarcado innumerables piezas en diversas campañas para clientes en Estados
                    Unidos y Venezuela. Majo destaca como una artista excepcionalmente versátil, mostrando un
                    asombroso talento actriz de doblaje y locutora. "
                  </p>
                </div>
                <div class="testimonial">
                  <div class="testimonial-content">
                    <div class="pic">
                      <!--                      <img class="" src="@/assets/images/80x80.png" alt="">-->
                    </div>
                    <span class="title">Maruchi Gonzalez</span>
                    <span class="post">Voiceover Dialect Coach for L’Oréal Paris</span>
                  </div>
                  <p class="description">
                    " Majo tiene una voz atractiva, cálida, conversacional y creíble que puede capturar los matices de
                    cualquier producto o servicio. Majo tiene una enunciación impecable en español y, con un acento
                    neutral, es absolutamente perfecta para comerciales, radio, videos, clips web y mucho más. "
                  </p>
                </div>
                <div class="testimonial">
                  <div class="testimonial-content">
                    <div class="pic">
                      <!--                      <img class="" src="@/assets/images/80x80.png" alt="">-->
                    </div>
                    <span class="title">Enmanuel Villalobos</span>
                    <span class="post">Reportero Telemundo 44</span>
                  </div>
                  <p class="description">
                    " María José es una de las profesionales más entregadas y apasionadas que conozco. Su precisión y
                    dedicación a la enseñanza trasciende límites. Mas allá de desarrollar habilidades de respiración y
                    voz en el aprendiz, como mentora su enfoque está basado en despertar la autoconfianza del alumno
                    como elemento vital para lograr sus objetivos. "
                  </p>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- testimonials ends -->

    <!-- get-in-touch starts -->
    <section id="get-in-touch" class="get-in-touch">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-7 col-lg-5 contactus-image no-padding">
            <img src="@/assets/images/contacto.png" class="img-reponsive images-height hidden-xs"/>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 col-lg-offset-1 form-section">
            <div class="contact-text">
              <h1>Hablemos</h1>
              <hr class="hr-bg">
              <p>Coméntame si quieres grabar mi voz para tu proyecto audiovisual o si necesitas una mentora que te
                acompañe en el proceso de despertar tu verdadera voz!</p>
            </div>
            <form id="registrationForm" class="contact-form form-horizontal" data-fv-framework="bootstrap"
                  data-fv-icon-valid="glyphicon glyphicon-ok" data-fv-icon-invalid="glyphicon glyphicon-remove"
                  data-fv-icon-validating="glyphicon glyphicon-refresh">

              <div class="form-group">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <input type="text" class="form-control" name="name" placeholder="Nombre" data-fv-row=".col-xs-6"
                         data-fv-notempty="true" data-fv-notempty-message="El nombre es requerido"/>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <input type="text" class="form-control" name="email" placeholder="Email" data-fv-row=".col-xs-6"
                         data-fv-notempty="true" data-fv-notempty-message="El email es requerido"
                         data-fv-emailaddress="true"
                         data-fv-emailaddress-message="The input is not a valid email address"/>
                </div>
              </div>

              <div class="form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <input type="text" class="form-control" name="web" placeholder="Website (opcional)"
                         data-fv-notempty="true" data-fv-notempty-message="The website is required" data-fv-uri="true"
                         data-fv-uri-message="The website address is not valid"/>
                </div>
              </div>

              <div class="form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <textarea id="message" cols="50" rows="4" class="form-control" placeholder="Mensaje" name="message"
                              data-fv-notempty="true" data-fv-stringlength="true" data-fv-stringlength-max="200"
                              data-fv-stringlength-message="El mensaje dede tener menos de 200 carateres"
                              data-fv-notempty-message="El mensaje es requerido"></textarea>
                </div>
              </div>

              <div class="form-group">
                <div class="col-xs-12 text-right contact-button">
                  <button type="submit" class="btn btn-primary" name="signup" value="Sign up">Envía un mensaje
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- get-in-touch ends -->

  </main>


</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

const slides = [
  {id: 'cartoon', title: 'cartoon', image: require('@/assets/images/logos/cartoon.png')},
  {id: 'nickelodeon', title: 'nickelodeon', image: require('@/assets/images/logos/nickelodeon.png')},
  {id: 'home_health', title: 'home_health', image: require('@/assets/images/logos/home_health.png')},
  {id: 'sony', title: 'sony', image: require('@/assets/images/logos/sony.png')},
  {id: 'univision', title: 'univision', image: require('@/assets/images/logos/univision.png')},
  {id: 'warner', title: 'warner', image: require('@/assets/images/logos/warner.png')},
  {id: 'XD', title: 'XD', image: require('@/assets/images/logos/XD.png')}
];

const testimonials = [
  {
    id: 'alberto',
    name: 'Alberto Chumaceiro',
    title: 'DIRECTOR GENERAL Y COFUNDADOR DE FÉNIX MEDIA',
    description: 'Trabajar con Majo ha sido un verdadero privilegio para Fénix Media y para mí a título personal. Nuestra colaboración ha abarcado innumerables piezas en diversas campañas para clientes en Estados Unidos y Venezuela. Majo destaca como una artista excepcionalmente versátil, mostrando un asombroso talento actriz de doblaje y locutora.'
  },
  {
    id: 'maruchi',
    name: 'Maruchi Gonzalez',
    title: 'VOICEOVER DIALECT COACH FOR L’Oréal Paris',
    description: 'Majo tiene una voz atractiva, cálida, conversacional y creíble que puede capturar los matices de cualquier producto o servicio. Majo tiene una enunciación impecable en español y, con un acento neutral, es absolutamente perfecta para comerciales, radio, videos, clips web y mucho más.'
  },
  {
    id: 'enmanuel',
    name: 'Enmanuel Villalobos',
    title: 'REPORTERO TELEMUNDO 44',
    description: 'María José es una de las profesionales más entregadas y apasionadas que conozco. Su precisión y dedicación a la enseñanza trasciende límites. Mas allá de desarrollar habilidades de respiración y voz en el aprendiz, como mentora su enfoque está basado en despertar la autoconfianza del alumno como elemento vital para lograr sus objetivos.'
  }
];

export default {
  name: "HomeView",
  data() {
    return {
      slides,
      testimonials
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    restartOwlCarousel() {
      $(".owl-logo-carousel").trigger('initialize.owl.carousel');

      this.$nextTick(() => {
        $('.owl-logo-carousel').trigger('initialize.owl.carousel');

      });
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        // this.restartStellar()
        this.restartOwlCarousel();
      }
    }
  },
  computed: {
    backgroundImage() {
      return `background-image: url(${require('@/assets/images/portada_majo.png')})`;
    },
    backgroundTestimonials() {
      return `background-image: url(${require('@/assets/images/testimonios.png')})`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth <= 768) {
        // Si es un dispositivo móvil, desactiva Stellar
        $.stellar('destroy');
      } else {
        // Si no es un dispositivo móvil, puedes inicializar Stellar (si es necesario)
        $.stellar();
      }
    });
  }
}
</script>

<style scoped>

</style>
