<script>
export default {
  name: 'VoiceoverView',
  computed: {
    backgroundImage() {
      return `background-image: url(${require('@/assets/images/voiceover_header.png')})`;

    }
  }
}
</script>

<template>
  <!-- banner starts -->
  <section :style="backgroundImage" id="banner" class="banner-voiceover" data-stellar-background-ratio=".2">
    <div class="layer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  text-center">
            <div class="blog-text">
              <h1 class="banner_heading">VOICEOVER</h1>
              <h6 class="slider-sub-heading gettoknow">Doblaje, locución comercial, canto</h6>
              <p></p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- banner ends -->

  <div class="main">

    <!-- cta starts -->
    <section id="cta" class="cta cta-gray-bg">
      <div class="container" >
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9">
            <div class="buynow-text">
              <h1 class="slider-heading buynow-head fadeInLeft wow">¿Necesitas una voz versátil y natural para tus proyectos audiovisuales?</h1>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 text-right">
            <div class="buynow-button">
              <router-link to="/contact" class="btn btn-gray-buynow">Contáctame</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- booss-demo -->
    <section id="booss-demos" class="booss-demos">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6 text-center">
            <div class="booss-demo-text fadeInDown wow" data-duration="1.5s">
              <h6>Escucha, ve, siente mis</h6>
              <h1>DEMOS</h1>
              <hr class="hr-bg bg9">
              <p> Llevo más de 18 años de forma ininterrumpida trabajando con la voz tanto en Latinoamérica como en Estados Unidos, en todas sus facetas, desde la radio, la publicidad, hasta doblaje de voces y cantante de doblaje musical. </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 fadeInLeft wow" data-wow-duration="1s">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12">
                <video width="568" height="440" controls poster="@/assets/images/videos/demo_cover_corporativo.png">
                  <source src="https://d15gxqlqiq7uiv.cloudfront.net/websites/majoestevez.com/media/videos/corporativo_demo.mp4" type="video/mp4">
                  Tu navegador no soporta el elemento de video.
                </video>
                <h1 class="booss-demo-img-text services-heading text-center">CORPORATIVO</h1>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12">
                <video width="568" height="440" controls poster="@/assets/images/videos/demo_cover_doblaje.png">
                  <source src="https://d15gxqlqiq7uiv.cloudfront.net/websites/majoestevez.com/media/videos/doblaje_demo.mp4" type="video/mp4">
                  Tu navegador no soporta el elemento de video.
                </video>
                <h1 class="booss-demo-img-text services-heading text-center">DOBLAJE</h1>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 fadeInRight wow" data-wow-duration="1s">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12">
                <video width="568" height="440" controls poster="@/assets/images/videos/demo_cover_doblaje_musical.png">
                  <source src="https://d15gxqlqiq7uiv.cloudfront.net/websites/majoestevez.com/media/videos/doblajemusical_demo.mp4" type="video/mp4">
                  Tu navegador no soporta el elemento de video.
                </video>
                <h1 class="booss-demo-img-text services-heading text-center">DOBLAJE MUSICAL</h1>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12">
                <video width="568" height="440" controls poster="@/assets/images/videos/demo_cover_publicidad.png">
                  <source src="https://d15gxqlqiq7uiv.cloudfront.net/websites/majoestevez.com/media/videos/loreal_demo.mp4" type="video/mp4">
                  Tu navegador no soporta el elemento de video.
                </video>
                <h1 class="booss-demo-img-text services-heading text-center">PUBLICIDAD</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- demos-testimonials starts -->
    <section id="demos-testimonials" class="demos-testimonials">
      <div class="layer">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-offset-1 col-lg-10">
              <div id="testimonial-slider">
                <div class="testimonial">
                  <div class="testimonial-content">
                    <div class="pic">
                      <!--                      <img class="" src="@/assets/images/80x80.png" alt="">-->
                    </div>
                    <span class="title">Memo Sauceda</span>
                    <span class="post">Locutor profesional
Productor</span>
                  </div>
                  <p class="description">
                    " Trabajar con un profesional es de gran ayuda cuando el tiempo de producción es ajustado. Majo siempre ofrece un desempeño puntual y de alta calidad de audio. Me siento afortunado de tenerla en mi lista de colaboradores."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- demos-testimonials ends -->

    <!-- mobile-devices -->
    <section id="extra-core-features" class="extra-core-features">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6">
            <div class="features-div extra-core-features-hover">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="features-list-text fadeInDown wow" data-wow-duration="1s">
                    <h6>Grabación, Entrega, Responsabilidad</h6>
                    <h1 class="feature-list-head-text">AUDIO PROFESIONAL</h1>
                    <hr class="hr-bg bg9">
                    <p class="feature-list-para">Tengo la experiencia y equipos para grabar profesionalmente la voz de tus proyectos y las herramientas óptimas en una cabina con acústica apropiada para realizar sesiones remotas de alta calidad.</p>
                    <p>Entrego los audios limpios para que puedas hacer la mezcla requerida en post producción.</p>

                    <h4>Le hago la vida más fácil a los productores y directores</h4>

                    <p>He grabado profesionalmente desde películas hasta comerciales, así que tengo capacidad de entregarte el audio con la intención requerida. Tengo capacidad de realizar acento 100% neutral para uso en toda Latinoamérica.</p>

                  </div>
                </div>
              </div>
              <div class="row fadeInLeft wow" data-wow-duration="1s">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="features-list">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="100%" height="100%" viewBox="0 0 208 97" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1.62366,0,0,1.62366,-49.289,-30.2608)">
        <path d="M41.427,65.031L41.427,59.913L48.03,59.913L48.03,58.136L41.427,58.136L41.427,53.446L49.087,53.446L49.087,51.669L39.485,51.669L39.485,66.808L49.217,66.808L49.217,65.031L41.427,65.031Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M52.739,66.808L62.686,66.808L62.686,65.031L54.681,65.031L54.681,51.669L52.739,51.669L52.739,66.808Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M67.534,65.031L67.534,59.913L74.137,59.913L74.137,58.136L67.534,58.136L67.534,53.446L75.194,53.446L75.194,51.669L65.592,51.669L65.592,66.808L75.324,66.808L75.324,65.031L67.534,65.031Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M89.98,51.669L85.449,59.549L80.896,51.669L78.846,51.669L78.846,66.808L80.723,66.808L80.723,54.624L84.499,61.219L86.376,61.219L90.152,54.603L90.152,66.808L92.03,66.808L92.03,51.669L89.98,51.669Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M98.467,65.031L98.467,59.913L105.069,59.913L105.069,58.136L98.467,58.136L98.467,53.446L106.126,53.446L106.126,51.669L96.525,51.669L96.525,66.808L106.256,66.808L106.256,65.031L98.467,65.031Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M119.466,51.669L119.466,63.275L111.418,51.669L109.778,51.669L109.778,66.808L111.72,66.808L111.72,55.181L119.768,66.808L121.43,66.808L121.43,51.669L119.466,51.669Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M136.6,51.669L124.323,51.669L124.323,53.446L129.48,53.446L129.48,66.808L131.443,66.808L131.443,53.446L136.6,53.446L136.6,51.669Z" style="fill:#000000;fill-rule:nonzero;"></path>
      <path d="M138.696,55.609C138.696,61.455 147.37,58.329 147.37,62.74C147.37,64.41 146.14,65.202 143.896,65.202C141.652,65.202 140.444,64.388 139.732,62.825L137.984,63.682C139.149,65.994 140.811,66.979 143.788,66.979C147.327,66.979 149.334,65.48 149.334,62.59C149.334,56.551 140.66,59.506 140.66,55.481C140.66,54.003 141.76,53.254 143.853,53.254C145.925,53.254 146.895,53.96 147.564,55.588L149.226,54.774C148.19,52.44 146.529,51.476 143.788,51.476C140.509,51.476 138.696,52.954 138.696,55.609Z" style="fill:#000000;fill-rule:nonzero;"></path>
    </g>
                        <g transform="matrix(1.62366,0,0,1.62366,-48.6028,-30.2608)">
        <g transform="matrix(1,0,0,1,12.5191,0)">
            <path d="M39.55,34.196C39.55,40.042 48.224,36.916 48.224,41.327C48.224,42.997 46.994,43.789 44.75,43.789C42.506,43.789 41.298,42.976 40.586,41.413L38.838,42.269C40.003,44.582 41.664,45.567 44.642,45.567C48.181,45.567 50.187,44.068 50.187,41.177C50.187,35.139 41.513,38.094 41.513,34.068C41.513,32.59 42.614,31.841 44.707,31.841C46.778,31.841 47.749,32.548 48.418,34.175L50.079,33.361C49.044,31.027 47.382,30.064 44.642,30.064C41.362,30.064 39.55,31.541 39.55,34.196Z" style="fill:#000000;fill-rule:nonzero;"></path>
        </g>
                          <g transform="matrix(1,0,0,1,12.5191,0)">
            <path d="M75.346,45.588C79.079,45.588 80.977,43.404 80.977,39.871L80.977,30.256L79.035,30.256L79.035,39.892C79.035,42.226 77.87,43.725 75.346,43.725C72.843,43.725 71.678,42.226 71.678,39.892L71.678,30.256L69.714,30.256L69.714,39.871C69.714,43.404 71.613,45.588 75.346,45.588Z" style="fill:#000000;fill-rule:nonzero;"></path>
        </g>
                          <g transform="matrix(1,0,0,1,12.5191,0)">
            <path d="M94.502,45.395L96.703,45.395L93.013,39.25C95.193,38.779 96.358,37.323 96.358,34.839C96.358,31.755 94.567,30.256 91.287,30.256L85.31,30.256L85.31,45.395L87.252,45.395L87.252,39.421L90.942,39.421L94.502,45.395ZM87.252,31.97L91.222,31.97C93.466,31.97 94.416,33.019 94.416,34.86C94.416,36.68 93.466,37.73 91.222,37.73L87.252,37.73L87.252,31.97Z" style="fill:#000000;fill-rule:nonzero;"></path>
        </g>
                          <g transform="matrix(1,0,0,1,12.5191,0)">
            <path d="M105.607,45.588C108.239,45.588 109.965,44.624 111.023,42.804L109.383,41.648C108.584,43.147 107.355,43.768 105.693,43.768C102.78,43.768 101.162,41.819 101.162,37.815C101.162,33.854 102.802,31.884 105.758,31.884C107.614,31.884 108.714,32.655 109.361,34.453L111.174,33.554C110.224,31.177 108.52,30.064 105.758,30.064C101.55,30.064 99.199,32.719 99.199,37.815C99.199,42.933 101.529,45.588 105.607,45.588Z" style="fill:#000000;fill-rule:nonzero;"></path>
        </g>
                          <g transform="matrix(1,0,0,1,12.5191,0)">
            <path d="M116.309,43.618L116.309,38.5L122.912,38.5L122.912,36.723L116.309,36.723L116.309,32.034L123.969,32.034L123.969,30.256L114.367,30.256L114.367,45.395L124.098,45.395L124.098,43.618L116.309,43.618Z" style="fill:#000000;fill-rule:nonzero;"></path>
        </g>
                          <g transform="matrix(1,0,0,1,12.5191,0)">
            <ellipse cx="59.746" cy="37.794" rx="7.66" ry="7.602" style="fill:rgb(167,225,99);"></ellipse>
        </g>
    </g>
</svg>

                    <!--                    <i class="icon dripicons-jewel"></i>-->
<!--                    <h1 class="services-heading">BEST PSD</h1>-->
<!--                    <hr class="home2-hr">-->
<!--                    <p>Sed porta sollicitudin eros, vel sagittis turpis consequat nec. Donec ac viverra ligula, in scelerisque leo.</p>-->
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="features-list">
                    <img src="@/assets/images/logos/avid.png" class="img-responsive" alt="footer-collections" />
<!--                    <i class="icon dripicons-trophy"></i>-->
<!--                    <h1 class="services-heading">CSS WINNER</h1>-->
<!--                    <hr class="home2-hr">-->
<!--                    <p>Sed porta sollicitudin eros, vel sagittis turpis consequat nec. Donec ac viverra ligula, in scelerisque leo.</p>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-5 col-lg-offset-1 col-lg-4 text-right respons-text-align">
            <img class="img-responsive" src="@/assets/images/equipos_voiceover_majo.png" alt="extra-core-feature">
          </div>
        </div>
      </div>
    </section>


  </div>

</template>

<style scoped>
.booss-demos {
  padding: 100px 0;
}
.demos-testimonials {
  background: url("@/assets/images/characters_majo.png") no-repeat;
  background-size: 100% 100%; }
.demos-testimonials .layer {
  background-color: rgba(50, 50, 50, 0.7);
  padding: 131px 0px; }
.demos-testimonials .layer #testimonial-slider .testimonial {
  margin: 20px 0; }
.demos-testimonials .layer #testimonial-slider .testimonial .testimonial-content {
  width: 20%;
  display: inline-block;
  float: left; }
.demos-testimonials .layer #testimonial-slider .testimonial .testimonial-content .pic {
  border-bottom: 1px solid #fff;
  display: inline-block;
  width: 30px; }
.demos-testimonials .layer #testimonial-slider .testimonial .testimonial-content .pic img {
  border-radius: 50%;
  margin-bottom: 30px;
  border: 1px solid #fff; }
.demos-testimonials .layer #testimonial-slider .testimonial .testimonial-content .title {
  font-size: 16px;
  color: #fff;
  font-family: 'oswald_regularregular';
  display: block;
  letter-spacing: 0.4em;
  margin-top: 30px;
  line-height: 30px; }
.demos-testimonials .layer #testimonial-slider .testimonial .testimonial-content .post {
  font-size: 11px;
  color: #fff;
  font-family: 'open_sanslight';
  letter-spacing: 0.05em;
  line-height: 30px; }
.demos-testimonials .layer #testimonial-slider .testimonial .description {
  display: inline-block;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 30px;
  color: #fff;
  margin: 0;
  position: relative;
  font-family: 'open_sanslight_italic';
  line-height: 48px; }
.demos-testimonials .layer #testimonial-slider .owl-controls {
  margin-top: 40px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-pagination {
  text-align: left;
  margin-top: 20px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-pagination .owl-page span {
  background: url("@/assets/images/new-all-image.png");
  background-position: -175px -21px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-pagination .owl-page.active span {
  background: url("@/assets/images/new-all-image.png");
  background-position: -130px -39px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-buttons .owl-prev,
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-buttons .owl-next {
  position: absolute;
  top: 39%;
  opacity: inherit;
  border-radius: 0px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-buttons .owl-prev {
  background: url("@/assets/images/new-all-image.png");
  background-position: -3px -89px;
  font-size: 25px;
  left: -260px;
  width: 63px;
  height: 65px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-buttons .owl-prev:hover {
  background: url("@/assets/images/new-all-image.png");
  background-position: -3px -162px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-buttons .owl-next {
  background: url("@/assets/images/new-all-image.png");
  background-position: -69px -88px;
  font-size: 25px;
  right: -260px;
  width: 63px;
  height: 65px; }
.demos-testimonials .layer #testimonial-slider .owl-controls .owl-buttons .owl-next:hover {
  background: url("@/assets/images/new-all-image.png");
  background-position: -70px -161px; }


</style>
