import {createRouter, createWebHashHistory} from "vue-router";
import { nextTick } from 'vue';

import HomeView from '@/modules/public/views/HomeView.vue';
import ContactView from '@/modules/public/views/ContactView.vue';
import PodcastView from "@/modules/public/views/PodcastView.vue";
import VoiceoverView from "@/modules/public/views/VoiceoverView.vue";
import NotFoundView from "@/modules/share/views/NoPageFound.vue";

const routes = [
    {
        path: '/',
        // component: HomeView
        component: () => import( /* webpackChunkName: "HomeView"*/ '@/modules/public/views/HomeView.vue')

    },
    {
        path: '/conoceme',
        component: () => import( /* webpackChunkName: "AboutView"*/ '@/modules/public/views/AboutView')
    },
    {
        path: '/contacto',
        component: ContactView
    },
    {
        path: '/podcast',
        component: PodcastView
    },
    {
        path: '/voiceover',
        component: VoiceoverView
    },
    {
        path: '/mentorias',
        component: () => import( /* webpackChunkName: "MentoringView"*/ '@/modules/public/views/MentoringView')
    },
    {
        path: '/politica-de-privacidad',
        name: 'politica-de-privacidad',
        component: () => import('@/modules/public/views/PolicyView.vue'),
        meta: { hideHeaderFooter: true }
    },
    {
        path: '/aviso-legal',
        name: 'aviso-legal',
        component: () => import('@/modules/public/views/LegalView.vue'),
        meta: { hideHeaderFooter: true }
    },
    {
        path: '/:pathMatch(.*)*'
        , component: NotFoundView
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { top: 0 }
    }
})

router.afterEach((to, from) => {
    if (typeof fbq === 'function') {
        fbq('track', 'PageView');
    }
});

export default router

