<script>

export default {
  name: 'ContactView',
  computed: {
    backgroundImage() {
      return `background-image: url(${require('@/assets/images/header_contactame.png')})`;

    }
  }
}
</script>

<template>

  <section :style="backgroundImage" id="banner-contact" class="banner-contact" data-stellar-background-ratio=".2">
    <div class="layer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="contact-banner-text text-center">
              <h1 class="services-heading banner_heading">CONTACTO</h1>
              <h6 class="slider-sub-heading gettoknow">&nbsp;</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- contact address -->
  <section id="contact" class="contact">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="contact-text">
            <h6>ESCRIBEME UN</h6>
            <h1>MENSAJE</h1>
            <hr class="hr-bg">
            <p>Si tienes alguna duda sobre Comunicación efectiva y necesitas <strong>consultoría</strong> para tu emprendimiento digital, deseas contratarme como tu <strong>mentora</strong> de voz y comunicación o requieres mi servicios como <strong>VoiceOver</strong>, estoy para servirte!  </p>

            <p>Para preguntas más sencillas, recuerda que también puedes comunicarte conmigo por mis redes sociales y seguirme en @majoestevez1.</p>

            <p>Por los momentos no estoy enviando saludos personalizados. En su momento indicaré cómo puedes solicitarlos 💖</p>
          </div>
          <form id="registrationForm" class="contact-form form-horizontal" data-fv-framework="bootstrap" data-fv-icon-valid="glyphicon glyphicon-ok" data-fv-icon-invalid="glyphicon glyphicon-remove" data-fv-icon-validating="glyphicon glyphicon-refresh">

            <div class="form-group">
              <div class="col-xs-12">
                <input type="text" class="form-control" name="name" placeholder="Nombre" data-fv-notempty="true" data-fv-notempty-message="El nombre es requerido" />
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-12">
                <input type="text" class="form-control" name="email" placeholder="Correo Electrónico" data-fv-notempty="true" data-fv-notempty-message="El Correo Electrónico es requerido" data-fv-emailaddress="true" data-fv-emailaddress-message="The input is not a valid email address" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-xs-12">
                <textarea id="message" cols="50" rows="4" class="form-control" placeholder="Mensaje" name="message" data-fv-notempty="true" data-fv-stringlength="true" data-fv-stringlength-max="200" data-fv-stringlength-message="El mensaje dede tener menos de 200 carateres" data-fv-notempty-message="El mensaje es requerido"></textarea>
              </div>
            </div>

            <div class="form-group">
              <div class="col-xs-12 contact-button">
                <button type="submit" class="btn btn-primary" name="signup" value="SEND MESSAGE">ENVIAR MENSAJE</button>
              </div>
            </div>

            <p>Al usar este formulario, también comprendes y accedes a las <strong><a :href="`${$publicPath}#/politica-de-privacidad`" target="_blank" rel="noopener noreferrer" class="underline-link">Política de Privacidad</a></strong> y el <strong><a :href="`${$publicPath}#/aviso-legal`" target="_blank" rel="noopener noreferrer" class="underline-link">Aviso Legal</a></strong> de esta web.</p>
          </form>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
          <div class="conatct-address">
            <h6>&nbsp;</h6>
            <h1>DIRECCIÓN</h1>
            <hr class="hr-bg">
<!--            <p><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </span></p>-->
            <ul>
<!--              <li>-->
<!--                <i class="icon dripicons-location pull-left" aria-hidden="true"></i>-->
<!--                <h1 class="contact-detailes">ADDRESS</h1>-->
<!--                <p>Envato Pty Ltd 13/2 Permanent st merbourne VLC 3000 Australia</p>-->
<!--              </li>-->
              <li>
                <i class="icon dripicons-phone pull-left" aria-hidden="true"></i>
                <h1 class="contact-detailes">WHATSAPP BUSINESS</h1>
                <p class="contact-no">+239 255 8858</p>
              </li>
              <li>
                <i class="icon dripicons-mail pull-left" aria-hidden="true"></i>
                <h1 class="contact-detailes">DIRECCIÓN EMAIL</h1>
                <p><a href="mailto:info@majoestevez.com?subject=Contacto - majoestevez.com">info@majoestevez.com</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped>

</style>
