<template>
  <Header v-if="!$route.meta.hideHeaderFooter"/>

  <div class="content">
    <router-view/>
  </div>

  <Footer v-if="!$route.meta.hideHeaderFooter"/>

</template>

<script>
import '@/assets/css/fonts.css';

import '@/assets/css/styles.css';


import {defineAsyncComponent} from "vue";

import Header from '@/modules/public/components/Header.vue'
import Footer from "@/modules/public/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Footer,
    Header
    // Header: defineAsyncComponent(() => import( /* webpackChunkName: "Header"*/ '@/modules/public/components/Header'))
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  //color: #2c3e50;
  //margin-top: 60px;
}
</style>
