<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Footer",
  data() {
    return {
      // publicPath: process.env.BASE_URL || 'http://localhost:8080'
    };
  }
})
</script>

<template>
  <section id="footer" class="footer">
    <div class="container foote-btm">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 footer-responsive-media">
          <div class="logo-image">
            <a href="#">
              <img src="@/assets/images/logo-light-blue.png" alt="logo-image" class="logo-normal" style="width: 135px" />
            </a>
          </div>
          <div class="booss-details">
            <p class="footer-logo-info">
              La transformación de tu voz y marca personal está a solo a un paso de tu decisión.
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 footer-responsive-media">
          <h6 class="creative-sub-heading">BLOG</h6>
          <div class="latest-news-list">
            <div class="latest-news">
              <a href="#"><img src="@/assets/images/50x50.png" class="pull-left" alt="latest-news-image" /></a>
              <h6><a href="#">Fusce lacinia arcu et nulla. Nulla vitae mauris non</a></h6>
              <span class="footer-meta">POSTES BY: <a href="#">TEXTTHEMES</a> | 12 JUNE, 2016</span>
            </div>
            <div class="latest-news">
              <a href="#"><img src="@/assets/images/50x50.png" class="pull-left" alt="latest-news-image" /></a>
              <h6><a href="#">Fusce lacinia arcu et nulla. Nulla vitae mauris non</a></h6>
              <span class="footer-meta">POSTES BY: <a href="#">TEXTTHEMES</a> | 12 JUNE, 2016</span>
            </div>
<!--            <div class="latest-news last-latest-news">-->
<!--              <a href="#"><img src="@/assets/images/50x50.png" class="pull-left" alt="latest-news-image" /></a>-->
<!--              <h6><a href="#">Fusce lacinia arcu et nulla. Nulla vitae mauris non</a></h6>-->
<!--              <span class="footer-meta">POSTES BY: <a href="#">TEXTTHEMES</a> | 12 JUNE, 2016</span>-->
<!--            </div>-->
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 footer-responsive-media">
          <h6 class="creative-sub-heading">SUSCRIBETE</h6>
          <div class="subscribe-details">
            <p class="footer-logo-info">
              Inscribiéndote en mi lista de e mails, tendrás insights semanales para potenciarte y tener acceso a ofertas y material exclusivo
            </p>
            <div class="subscribe-email">
              <div class="input-group">
                <input id="subscribe-email" type="email" class="form-control" name="email" value="" placeholder="Tu email">
                <span class="input-group-addon"><i class="fa fa-sign-in"></i></span>
              </div>
            </div>
<!--            <p class="footer-logo-info">*Aliquam vehicula mollis urna vel</p>-->
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <h6 class="creative-sub-heading">COLLECTIONS</h6>
          <div class="footer-image-girds">
            <a href="https://www.instagram.com/p/CwQWCsLgI1E/" target="_blank"><img src="@/assets/images/footer/footer_1.png" class="img-responsive" alt="footer-collections" /></a>
            <a href="https://www.instagram.com/p/CwvC6jmA3IZ/" target="_blank"><img src="@/assets/images/footer/footer_2.png" class="img-responsive" alt="footer-collections" /></a>
            <a href="https://www.instagram.com/p/CxdpfSMR_zJ/" target="_blank"><img src="@/assets/images/footer/footer_3.png" class="img-responsive mrg-r-none" alt="footer-collections" /></a>
            <a href="https://www.instagram.com/p/Cv7w8fUAXBn/" target="_blank"><img src="@/assets/images/footer/footer_4.png" class="img-responsive" alt="footer-collections" /></a>
            <a href="https://www.instagram.com/p/Cu6-oOGg3gu/" target="_blank"><img src="@/assets/images/footer/footer_5.png" class="img-responsive" alt="footer-collections" /></a>
            <a href="https://www.instagram.com/p/Cu2ssUkRRgf/" target="_blank"><img src="@/assets/images/footer/footer_6.png" class="img-responsive mrg-r-none" alt="footer-collections" /></a>
<!--            <a href="#"><img src="@/assets/images/footer/footer_1.png" class="img-responsive" alt="footer-collections" /></a>-->
<!--            <a href="#"><img src="@/assets/images/footer/footer_1.png" class="img-responsive" alt="footer-collections" /></a>-->
<!--            <a href="#"><img src="@/assets/images/footer/footer_1.png" class="img-responsive mrg-r-none" alt="footer-collections" /></a>-->
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-container">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <p class="copyrights boss-slider-info ">&copy; 2023.All rights reserved.Powered by <strong><a hef="#">PULSAR-IO</a></strong>.</p>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 disclaimer-links">
            <p class="copyrights boss-slider-info centered-text"><strong><a :href="`${$publicPath}#/politica-de-privacidad`" target="_blank" rel="noopener noreferrer" class="underline-link">Política de Privacidad</a></strong></p>
            <p class="copyrights boss-slider-info centered-text"><strong><a :href="`${$publicPath}#/aviso-legal`" target="_blank" rel="noopener noreferrer" class="underline-link">Aviso Legal</a></strong></p>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <div class="footer-social-icons">
              <a href="https://www.facebook.com/majoestevez1" target="_blank" class="footer-icon-link facebook"><i class="fa fa-facebook " aria-hidden="true"></i></a>
              <a href="https://www.instagram.com/majoestevez1/" target="_blank" class="footer-icon-link twitter"><i class="fa fa-instagram" aria-hidden="true"></i></a>
              <a href="https://www.youtube.com/@MajoEstevez1" target="_blank" class="footer-icon-link google-plus"><i class="fa fa-youtube-square" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style>
.underline-link {
  text-decoration: underline !important;
}

.centered-text {
  text-align: center;
}

.disclaimer-links {
  display: flex;
  justify-content: space-around;

}
</style>
